import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "./containers/Home";
import ListView from "./containers/ListView";

function App() {
    return (
        <div className="App">
            <Router>
                <Route exact path="/" component={Home} />
                <Route exact path="/list" component={ListView} />
            </Router>
        </div>
    );
}

export default App;
