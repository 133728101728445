const neighborhoods = [
    "Astoria",
    "Auburndale",
    "Bayside",
    "Chelsea",
    "Chinatown",
    "East Harlem",
    "East Village",
    "Financial District",
    "Flatiron",
    "Floral Park",
    "Flushing",
    "Forest Hills",
    "Gramercy",
    "Greenwich Village",
    "Harlem",
    "Hunter's Point",
    "Inwood",
    "Jackson Heights",
    "Jamaica",
    "Kips Bay",
    "Little Italy",
    "Lower East Side",
    "Manhattan Valley",
    "Meatpacking Industry",
    "Midtown",
    "Midtown East",
    "Midtown West",
    "Morningside Heights",
    "Noho",
    "Nolita",
    "Park Slope",
    "Soho",
    "South Slope",
    "South Street Seaport",
    "South Village",
    "Staten Island",
    "Sunnyside",
    "Theater District",
    "Tribeca",
    "Union Square",
    "Upper East Side",
    "Upper West Side",
    "Washington Heights",
    "West Village"
];

export default neighborhoods;
