import gql from "graphql-tag";

export const FIND_PROMO_BY_ID = gql`
    query findPromotionById($id: Int!) {
        findPromotionById(id: $id) {
            id
            startTime
            endTime
        }
    }
`;

export const FIND_ALL_BARS = gql`
    query finadAllBars {
        findAllBars {
            id
            name
            address
            neighborhood
            promotions {
                id
                day
                description
                startTime
                endTime
                tags
            }
        }
    }
`;
