import React from "react";
import moment from "moment";
import { Row, Col, Typography } from "antd";
const { Text, Title } = Typography;

const BarListItem = props => {
    const bar = props.data;
    let promo;
    if (props.day === "ANY") {
        promo = bar.promotions[0];
    } else {
        promo = bar.promotions.filter(promo => {
            return props.day === promo.day;
        })[0];
    }
    const format = "HH:mm a";
    const outFormat = "hh:mm a";
    const start = moment(promo.startTime, format).format(outFormat);
    const end = moment(promo.endTime, format).format(outFormat);
    const title = bar.name + " (" + bar.neighborhood + ")";
    return (
        <Row type="flex" justify="center" style={{ width: "100%" }}>
            <Col span={10} style={{ textAlign: "left" }}>
                <Title level={4}>{title}</Title>
                <div>{promo.description}</div>
            </Col>
            <Col span={8} style={{ textAlign: "left" }}>
                <Row align="middle" style={{ height: "100%" }}>
                    <Text>
                        <strong>Address:</strong> {bar.address}
                    </Text>
                    <br />
                    <Text>
                        <strong>Hours:</strong> {start} - {end}
                    </Text>
                </Row>
            </Col>
            <br />
        </Row>
    );
};

export default BarListItem;
