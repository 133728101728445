import React, { Component } from "react";
import ReactBodymovin from "react-bodymovin";

class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: this.props.link
        };
    }
    _createOptions = link => {
        return {
            loop: true,
            speed: 1,
            autoplay: true,
            prerender: true,
            animationData: link
        };
    };

    render() {
        const { link } = this.state;
        const options = this._createOptions(link);
        return (
            <div style={styles.logo}>
                <ReactBodymovin options={options} />
            </div>
        );
    }
}

const styles = {
    logo: {
        heigh: "400px",
        width: "400px",
        margin: "30px"
    }
};

export default Logo;
