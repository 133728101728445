import React, { Component } from "react";
import { Tag } from "antd";
import tags from "../data/tags";
const { CheckableTag } = Tag;

class TagGroup extends Component {
    constructor(props) {
        super(props);
        let map = {};
        tags.forEach(tag => {
            map[tag] = false;
        });
        this.state = {
            tags: tags,
            showMap: map,
            selectedTags: new Set()
        };
    }

    onChange = (value, name) => {
        this.setState(
            state => {
                state.showMap[name] = value;
                if (state.selectedTags.has(name)) {
                    state.selectedTags.delete(name);
                } else {
                    state.selectedTags.add(name);
                }
                return {
                    showMap: state.showMap,
                    selectedTags: state.selectedTags
                };
            },
            () => {
                this.props.handledealTagsSelection([
                    ...this.state.selectedTags
                ]);
            }
        );
    };

    tag = name => {
        return (
            <CheckableTag
                key={name}
                checked={this.state.showMap[name]}
                onChange={value => this.onChange(value, name)}
            >
                {name}
            </CheckableTag>
        );
    };

    render() {
        const { tags } = this.state;
        const renderTags = tags.map(tag => {
            return this.tag(tag);
        });
        return <div>{renderTags}</div>;
    }
}

export default TagGroup;
