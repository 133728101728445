import React from "react";
import moment from "moment";
import { Row, Col, Typography, Button } from "antd";
const { Title } = Typography;

const BarFullInfo = props => {
    const displayPromos = bar => {
        return bar.promotions.map(promo => (
            <div>
                <strong>{promo.day}: </strong>
                {promo.description}
            </div>
        ));
    };
    const format = "HH:mm a";
    const outFormat = "hh:mm a";
    if (props.bar) {
        const title = props.bar.name + " (" + props.bar.neighborhood + ")";
        const momentFormat = (promo, time) => {
            return moment(promo[time], format).format(outFormat);
        };
        const startTime = momentFormat(props.bar.promotions[0], "startTime");
        const endTime = momentFormat(props.bar.promotions[0], "endTime");
        const link =
            "http://google.com/search?q=" + props.bar.name + "+manhattan";
        return (
            <div>
                <Row>
                    <Title>{title}</Title>
                </Row>
                <Row
                    type="flex"
                    justify="space-around"
                    style={{ width: "100%", paddingBottom: "50px" }}
                >
                    <Col>
                        <Title level={4}>Schedule</Title>
                        {displayPromos(props.bar)}
                    </Col>
                    <Col>
                        <Title level={4}>Info</Title>
                        <strong>Hours: </strong>
                        {startTime}, {endTime}
                        <br />
                        <strong>Address: </strong>
                        {props.bar.address}
                        <br />
                        <a href={link}>
                            <Button
                                style={{ marginTop: "10px" }}
                                type="primary"
                            >
                                Site
                            </Button>
                        </a>
                    </Col>
                    <Col />
                </Row>
            </div>
        );
    } else {
        return;
    }
};

export default BarFullInfo;
