import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BarListItem from "./BarListItem";
import { List, Typography, BackTop, Drawer } from "antd";
import BarFullInfo from "./BarFullInfo";

class ListItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barData: this.props.barData,
            neighborhoods: this.props.neighborhoods,
            day: this.props.day,
            dealTags: this.props.dealTags,
            time: this.props.time,
            drawerIsOpen: false,
            selectedBar: null
        };
    }

    componentWillMount() {
        this.filterData(
            this.state.barData,
            this.state.neighborhoods,
            this.state.day,
            this.state.dealTags,
            this.state.time
        );
    }

    componentWillReceiveProps(nextProps) {
        this.filterData(
            nextProps.barData,
            nextProps.neighborhoods,
            nextProps.day,
            nextProps.dealTags,
            nextProps.time
        );
    }

    _handleDoorOpen = bar => {
        this.setState({
            drawerIsOpen: true,
            selectedBar: bar
        });
    };

    filterData = (
        stateBarData,
        stateNeighborhoods,
        stateDay,
        stateDealTags,
        stateTime
    ) => {
        const filterOnNeighborhood = barData => {
            if (stateNeighborhoods.length > 0) {
                return barData.filter(bar => {
                    return stateNeighborhoods.includes(bar.neighborhood);
                });
            } else {
                return barData;
            }
        };
        const filterOnDay = barData => {
            if (stateDay !== "ANY") {
                return barData.filter(bar => {
                    let hasPromoOnDay = false;
                    bar.promotions.forEach(promo => {
                        if (promo.day === stateDay) {
                            hasPromoOnDay = true;
                        }
                    });
                    return hasPromoOnDay;
                });
            } else {
                return barData;
            }
        };
        const filterOnTags = barData => {
            const checkDay = (promotion, tags) => {
                let hasTags = true;
                tags.forEach(tag => {
                    if (!promotion.tags.includes(tag)) {
                        hasTags = false;
                    }
                });
                return hasTags;
            };
            return barData.filter(bar => {
                let dayMap = {};
                bar.promotions.forEach(promo => {
                    dayMap[promo.day] = checkDay(promo, stateDealTags);
                });
                if (stateDay !== "ANY") {
                    return dayMap[stateDay];
                } else {
                    return Object.values(dayMap).includes(true);
                }
            });
        };
        const filterOnTime = barData => {
            return barData.filter(bar => {
                let promo = bar.promotions.filter(promo => {
                    return stateDay === promo.day;
                })[0];
                const format = "HH:mm";
                const start = moment(promo.startTime, format);
                const end = moment(promo.endTime, format);
                return stateTime.isBetween(start, end);
            });
        };
        let barDataMutable = stateBarData.slice();
        barDataMutable = filterOnNeighborhood(barDataMutable);
        barDataMutable = filterOnDay(barDataMutable);
        barDataMutable = filterOnTags(barDataMutable);
        if (stateDay !== "ANY") {
            barDataMutable = filterOnTime(barDataMutable);
        }
        this.setState({
            barData: barDataMutable,
            neighborhood: stateNeighborhoods,
            day: stateDay,
            dealTags: stateDealTags,
            time: stateTime
        });
    };

    render() {
        const { barData, day, drawerIsOpen, selectedBar } = this.state;
        return (
            <div>
                <List
                    header={<Typography>{barData.length} Bars</Typography>}
                    footer={<BackTop />}
                    bordered
                    dataSource={barData}
                    renderItem={bar => (
                        <List.Item
                            style={{ width: "100%" }}
                            onClick={() => this._handleDoorOpen(bar)}
                        >
                            <BarListItem key={bar.id} data={bar} day={day} />
                        </List.Item>
                    )}
                />
                <Drawer
                    placement="bottom"
                    closable={true}
                    onClose={_ => this.setState({ drawerIsOpen: false })}
                    visible={drawerIsOpen}
                    height="300px"
                >
                    <BarFullInfo bar={selectedBar} />
                </Drawer>
            </div>
        );
    }
}

ListItems.propTypes = {
    barData: PropTypes.array.isRequired,
    neighborhoods: PropTypes.array.isRequired,
    day: PropTypes.string.isRequired,
    dealTags: PropTypes.array.isRequired,
    time: PropTypes.object.isRequired
};

export default ListItems;
