import React, { Component } from "react";
import PropTypes from "prop-types";
import neighborhoodOptions from "../data/neighborhoods";
import days from "../data/days";
import TagGroup from "./TagGroup";
import { TimePicker, Typography } from "antd";
import { Select, Row, Col } from "antd";
const { Option } = Select;

class FilterMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            neighborhoods: this.props.neighborhoods,
            day: this.props.day,
            dealTags: [],
            time: this.props.time
        };
    }

    handleNeighborhoodSelection = neighborhoods => {
        this.setState({
            neighborhoods: neighborhoods
        });
        this.props.handleNeighborhoodChange(neighborhoods);
    };
    handleDaySelection = day => {
        this.setState({
            day: day
        });
        this.props.handleDayChange(day);
    };
    handledealTagsSelection = dealTags => {
        this.setState({
            dealTags: dealTags
        });
        this.props.handledealTagsChange(dealTags);
    };
    handleTimeSelection = time => {
        this.setState({
            time: time
        });
        this.props.handleTimeChange(time);
    };

    render() {
        const { neighborhoods, day, time } = this.state;
        return (
            <div style={styles.menuContainer}>
                {/* <div style={styles.menuContainer}>p</div> */}
                <form className={"SelectForm"} autoComplete="off">
                    <Row type="flex" justify="center">
                        <Col span={5}>
                            <Typography>Neighborhood(s)</Typography>
                            <Select
                                dropdownMatchSelectWidth={false}
                                mode="multiple"
                                autoFocus={true}
                                style={{ width: "95%" }}
                                value={neighborhoods}
                                onChange={value =>
                                    this.handleNeighborhoodSelection(value)
                                }
                            >
                                {neighborhoodOptions.map(option => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <Typography>Day</Typography>
                            <Select
                                value={day}
                                style={{ width: "95%" }}
                                onChange={value =>
                                    this.handleDaySelection(value)
                                }
                            >
                                {days.map(option => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={3}>
                            <Typography>Time</Typography>
                            <TimePicker
                                onChange={value =>
                                    this.handleTimeSelection(value)
                                }
                                style={{ width: "95%" }}
                                value={time}
                                minuteStep={30}
                                secondStep={60}
                                format={"hh:mm A"}
                                use12Hours={true}
                            />
                        </Col>
                    </Row>
                    <Row style={styles.TagContainer}>
                        <TagGroup
                            handledealTagsSelection={
                                this.handledealTagsSelection
                            }
                        />
                    </Row>
                </form>
            </div>
        );
    }
}
FilterMenu.propTypes = {
    neighborhoods: PropTypes.array.isRequired,
    dealTags: PropTypes.array.isRequired,
    day: PropTypes.string.isRequired,
    time: PropTypes.object.isRequired,
    handleTimeChange: PropTypes.func.isRequired,
    handleDayChange: PropTypes.func.isRequired,
    handleNeighborhoodChange: PropTypes.func.isRequired,
    handledealTagsChange: PropTypes.func.isRequired
};

const styles = {
    menuContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        color: "red",
        backgroundColor: "white",
        borderBottomColor: "grey",
        borderBottomThickness: 1
    },
    TagContainer: {
        marginTop: 15
    }
};

export default FilterMenu;
