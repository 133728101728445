import React, { Component } from "react";
import Header from "../components/Header";
import { Row } from "antd";
import SelectNeighborhoodForm from "../components/SelectNeighborhoodForm";

class Home extends Component {
    render() {
        return (
            <Row
                style={{ height: "100%" }}
                type="flex"
                align="middle"
                justify="center"
            >
                <Header />
                <SelectNeighborhoodForm />
            </Row>
        );
    }
}

export default Home;
