import React, { Component } from "react";
import FilterMenu from "../components/FilterMenu";
import ListItems from "../components/ListItems";
import ErrorMessage from "../components/Error";
import Loading from "../components/Loading";
import { Query } from "react-apollo";
import { FIND_ALL_BARS } from "../queries/queries";
import moment from "moment";
import { Affix, Row } from "antd";

class ListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            neighborhoods: this.props.location.state
                ? [this.props.location.state.neighborhood]
                : [],
            day: "ANY",
            dealTags: [],
            time: moment("17:00:00", "HH,mm")
        };
    }

    _handleNeighborhoodChange = neighborhoods => {
        this.setState({
            neighborhoods: neighborhoods
        });
    };
    _handleDayChange = day => {
        this.setState({
            day: day
        });
    };
    _handledealTagsChange = dealTags => {
        this.setState({
            dealTags: dealTags
        });
    };
    _handleTimeChange = time => {
        this.setState({
            time: time
        });
    };

    render() {
        const { neighborhoods, day, dealTags, time } = this.state;
        return (
            <div>
                <Affix>
                    <FilterMenu
                        neighborhoods={neighborhoods}
                        dealTags={dealTags}
                        day={day}
                        time={time}
                        handleNeighborhoodChange={
                            this._handleNeighborhoodChange
                        }
                        handleDayChange={this._handleDayChange}
                        handledealTagsChange={this._handledealTagsChange}
                        handleTimeChange={this._handleTimeChange}
                    />
                </Affix>
                <Query query={FIND_ALL_BARS}>
                    {({ data, loading, error }) => {
                        if (error) {
                            return (
                                <Row type="flex" justify="center">
                                    <ErrorMessage error={error} />
                                </Row>
                            );
                        }
                        if (loading) {
                            return (
                                <Row type="flex" justify="center">
                                    <Loading />;
                                </Row>
                            );
                        } else {
                            return (
                                <ListItems
                                    barData={data.findAllBars}
                                    neighborhoods={neighborhoods}
                                    day={day}
                                    dealTags={dealTags}
                                    time={time}
                                />
                            );
                        }
                    }}
                </Query>
            </div>
        );
    }
}

export default ListView;
