import React from "react";
import { Typography } from "antd";
const { Title, Text } = Typography;

const Header = () => {
    return (
        <div style={{ marginBottom: "20px", marginRight: "10px" }}>
            <br />
            <Title level={1}>HppyHr Fndr NYC</Title>
            <Text>Pick a neighborhood to explore happy hours near you</Text>
            <br />
        </div>
    );
};

export default Header;
