const tags = [
    "Beer",
    "Bottomless",
    "Cocktail",
    "Margaritas",
    "Mixed Drinks",
    "Oysters",
    "Sangria",
    "Shots",
    "Tacos",
    "Wings",
    "Wine"
];

export default tags;
