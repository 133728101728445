import React, { Component } from "react";
import neighborhoods from "../data/neighborhoods";
import { Link } from "react-router-dom";
import Button from "antd/lib/button";
import { Row, Select, Icon } from "antd";
const { Option } = Select;

class SelectNeighborhoodForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "East Village"
        };
    }

    handleChange(value) {
        this.setState({ selected: value });
    }

    render() {
        return (
            <Row>
                <Select
                    value={this.state.selected}
                    style={{ width: 220, marginBottom: "15px" }}
                    size="large"
                    onChange={value => this.handleChange(value)}
                >
                    {neighborhoods.map(option => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </Select>
                <br />
                <Link
                    to={{
                        pathname: "/list",
                        state: {
                            neighborhood: this.state.selected
                        }
                    }}
                    style={{ textDecoration: "None" }}
                >
                    <Button type="primary" size="large">
                        Go
                        <Icon type="right" />
                    </Button>
                </Link>
            </Row>
        );
    }
}

export default SelectNeighborhoodForm;
