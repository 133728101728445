import React from "react";
import Logo from "./Logo";
import errorMessage from "../img/error2.json";

const ErrorMessage = ({ error }) => (
    <div className="ErrorMessage">
        <Logo link={errorMessage} />
        <small>{error.toString()}</small>
    </div>
);

export default ErrorMessage;
